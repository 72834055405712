import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Switch } from '@opengov/component-library/capital';
import { FigmaEmbed, PatternExample, UsageBlock } from '../../../components';
import LayoutComponents from '../../../components/capital/Layouts/LayoutComponents';
export const _frontmatter = {
  "title": "Switch",
  "componentId": "switch",
  "description": "Switches are used to gather boolean data from a user.",
  "activeTab": "components"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = LayoutComponents;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p><strong parentName="p">{`Documentation in progress---------------------`}</strong></p>
    <p>{`Think of the toggle switch as a physical power switch for the product: you flip it on or off when you want to enable or disable functionality. Only use a switch for a singular binary operation that take effect right after the user flips the toggle switch.`}</p>
    <p>{`To make the toggle switch easy to understand, label it with one or two words, preferably nouns, that describe the functionality it controls. For example, "Email Alerts" or "Account Strings."`}</p>
    <h2>{`Variants`}</h2>
    <PatternExample example={<Switch label="Example Switch" mdxType="Switch" />} mdxType="PatternExample" />
    <h2>{`Configuration`}</h2>
    <PatternExample example={<Switch.Small label="Example Switch" />} title="Small" mdxType="PatternExample" />
    <h3>{`Quick Reference Specs`}</h3>
    <ul>
      <li parentName="ul">{`Type: 16pt Benton Condensed`}</li>
      <li parentName="ul">{`Icon Size: 16x16`}</li>
      <li parentName="ul">{`Total Height: 38px`}</li>
    </ul>
    <h2>{`States`}</h2>
    <PatternExample example={<Switch checked={true} mdxType="Switch" />} title="Checked" mdxType="PatternExample" />
    <PatternExample example={<Switch disabled={true} mdxType="Switch" />} title="Disabled" mdxType="PatternExample" />
    <h2>{`Usage`}</h2>
    <UsageBlock mdxType="UsageBlock">
  <UsageBlock.Correct>
    <p>Only use a switch for a singular binary operation that take effect right after the user flips the toggle switch.</p>
  </UsageBlock.Correct>
  <UsageBlock.Incorrect>
    <p>Avoid using the switch for a group of related choices. Instead, use a checkbox group.</p>
  </UsageBlock.Incorrect>
    </UsageBlock>
    <h2>{`Layout`}</h2>
    <PatternExample example={"..."} title="..." description={"Description about layout structure"} mdxType="PatternExample" />
    <h2>{`Content`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      